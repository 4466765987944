<template>
    <div class="card-tail" v-loading="$waiting.is('loading')">
        <div v-if="invoices.awaitingApprovalInvoices && invoices.awaitingApprovalInvoices.length > 0" style="margin-bottom:20px;">
            <h2 class="font-semibold text-lg mb-4">Att hantera</h2>
            <el-table :data="invoices.awaitingApprovalInvoices" size="small" @current-change="handleDraftInvoiceClick">
                <el-table-column prop="factoringPaymentStatus" label="Status" sortable="custom" width="200" />
                <el-table-column prop="customerName" label="Kund" />
                <el-table-column prop="grossAmount" label="Bruttobelopp" align="right" width="200">
                    <template slot-scope="scope">{{ scope.row.grossAmount | swedishNumberFormat }} SEK</template>
                </el-table-column>
                <el-table-column prop="invoiceDate" label="Fakturadatum" sortable="custom" align="right" width="200" />
                <el-table-column prop="dueDate" label="Förfallodatum" sortable="custom" align="right" width="200" />
            </el-table>
        </div>
        <div v-else style="margin-bottom:15px;">
            <p>Inga ohanterade fakturor än</p>
        </div>
        <div v-if="invoices.otherStatusInvoices && invoices.otherStatusInvoices.length > 0" v-loading="$waiting.is('loading')">
            <h2 class="font-semibold text-lg mb-4">Hanterade</h2>
            <el-table :data="invoices.otherStatusInvoices" size="small" @current-change="handleInvoiceClick" :row-class-name="getRowColor">
                <el-table-column prop="factoringPaymentStatus" label="Status" sortable="custom" width="90" />
                <el-table-column prop="invoiceNumber" label="F#" width="100" />
                <el-table-column prop="customerName" label="Kund" />
                <el-table-column prop="remainingAmount" label="Kvar att betala" align="right" width="130">
                    <template slot-scope="scope">{{ scope.row.remainingAmount | swedishNumberFormat }} SEK</template>
                </el-table-column>
                <el-table-column prop="grossAmount" label="Bruttobelopp" sortable="custom" align="right" width="140">
                    <template slot-scope="scope">{{ scope.row.grossAmount | swedishNumberFormat }} SEK</template>
                </el-table-column>
                <el-table-column prop="invoiceDate" label="Fakturadatum" sortable="custom" align="right" width="150" />
                <el-table-column prop="dueDate" label="Förfallodatum" sortable="custom" align="right" width="150" />
                <el-table-column prop="timeWhenPaid" label="Utbetald" sortable="custom" align="right" width="130" />
                <el-table-column prop="commission" label="Provision" sortable="custom" align="right" width="110" />
            </el-table>
        </div>
        <div v-else style="margin-bottom:15px;">
            <p>Inga hanterade fakturor än</p>
        </div>
    </div>
</template>

<script>
import Api from "./factoring.api";

export default {
    data() {
        return {
            initialCommissionPercentage: "",
            commissionPercentage: "",
            fileList: [],
            isRenderedData: false,
            loading: false,
            modalVisible: false,
            currentSwitchKey: "",
            editingInputField: false,
            activeComponent: "ClientFactoringInvoices",
            invoiceCustomerDetails: {},
            commentsByDecisionType: {},
            header: "",
            isSwitchOn: true,
            decisions: [],
            selectedFactoringDecisionId: null,
            invoices: {
                awaitingApprovalInvoices: [],
                otherStatusInvoices: [],
            },
            selectedInvoiceId: null,
            factoringInvoiceDecisionId: 0,

            switchStates: {
                switchStatesIsCustomerBolagerietClient: false,
                switchStatesIsCreditRatingAtLeastThree: false,
                switchStatesIsCreditLimitZero: false,
                switchStatesHasActiveACaseAtKfm: false,
                switchStatesHasAuditNote: false,
                switchStatesIsInvAmountExceeding50PercentOfPaymentNotesOrDebtBalance: false,
                switchStatesHasReqMoreThan8TimesPast3Months: false,
                switchStatesHasFSkatt: false,
                switchStatesHasVat: false,
                switchStatesIsAmountMaximum25PercentOfYearlyRevenue: false,
                switchStatesIsCustomerInOurSystem: false,
                switchStatesHasLessThan3Over5Days: false,
                switchStatesIsAveragePayTimeMax15Days: false,
                switchStatesIsLargerThan60Days: false,
                switchStatesInvoicesApproved: false,
            },
            fields: [
                { name: "customerName", editedValue: "", editing: false },
                { name: "organizationNumber", editedValue: "", editing: false },
                { name: "box", editedValue: "", editing: false },
                { name: "zipCode", editedValue: "", editing: false },
                { name: "city", editedValue: "", editing: false },
                { name: "email", editedValue: "", editing: false },
                { name: "emailCC", editedValue: "", editing: false },
                { name: "contactPerson", editedValue: "", editing: false },
                { name: "phoneNumber", editedValue: "", editing: false },
            ],
            originalDetails: {},
            retrievedCustomerInfo: {},
            formData: {
                comment: "",
            },
            userName: "",
            filesData: [],
            selectedDocument: null,
            selectedFileIndex: null,
        };
    },

    watch: {
        $route: function() {
            this.activeComponent = this.$route.meta.activeTab;
            this.getClientHeader();
        },
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Klienter");
    },

    async created() {
        this.getClientHeader();
        this.selectedInvoiceId = null;
        this.activeComponent = this.$route.meta.activeTab;
        this.$waiting.start("loading");

        try {
            this.decisions = await Api.getClientsFactoringDecisionsList(this.$route.params.clientId);
            this.selectedFactoringDecisionId = this.decisions[0].factoringDecisionId;

            this.invoices = await Api.getInvoicesForFactoringDecisionId(this.$route.params.clientId, this.selectedFactoringDecisionId, this.selectedInvoiceId);
            this.invoices.awaitingApprovalInvoices = this.invoices.awaitingApprovalInvoices;
            this.invoices.otherStatusInvoices = this.invoices.otherStatusInvoices;
        } catch (error) {
            console.error("Error loading data:", error);
        } finally {
            this.$waiting.end("loading");
        }
    },

    methods: {
        getRowColor(invoice) {
            switch (invoice.row.factoringPaymentStatus) {
                case "Betald":
                    return "row-green";
                case "Förfallen" && "Nekad":
                    return "row-red";
                case "Överbetald":
                    return "row-yellow";
                default:
                    return {};
            }
        },

        async getClientHeader() {
            this.header = await Api.getClientHeader(this.$route.params.clientId);
        },

        handleDraftInvoiceClick(invoice) {
            this.$waiting.start("loading");
            this.isRenderedData = true;
            this.selectedInvoiceId = invoice.factoringInvoiceId;

            try {
                const routePath = `/clientApproved/${this.$route.params.clientId}/getDraftInvoiceInfo/${this.selectedInvoiceId}`;

                this.$router.push({
                    path: routePath,
                    query: {
                        selectedInvoiceId: this.selectedInvoiceId,
                    },
                });
            } catch (error) {
                console.error("Error handling invoice click:", error);
            } finally {
                this.$waiting.end("loading");
            }
        },

        handleInvoiceClick(invoice) {
            this.$waiting.start("loading");
            this.isRenderedData = true;
            this.selectedInvoiceId = invoice.factoringInvoiceId;

            try {
                const routePath = `/clientApproved/${this.$route.params.clientId}/getInvoiceInfo/${this.selectedInvoiceId}`;

                this.$router.push({
                    path: routePath,
                    query: {
                        selectedInvoiceId: this.selectedInvoiceId,
                    },
                });
            } catch (error) {
                console.error("Error handling invoice click:", error);
            } finally {
                this.$waiting.end("loading");
            }
        },
    },
};
</script>

<style scoped>
.dark-input .el-input__inner::placeholder {
    color: #666;
}
.comment-field {
    position: relative;
    max-width: 500px;
}

.comment-header {
    position: absolute;
    top: -10px;
    background-color: white;
    padding: 0 10px;
    font-size: 13px;
    font-weight: 550;
    left: 20px;
}

.comment-textarea {
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 13px;
    padding: 20px 10px;
    width: 100%;
    height: 90px !important;
    resize: vertical;
}

.comment-textarea::placeholder {
    color: rgba(0, 0, 0, 0.6);
}

.switch-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.headerAndSwitch {
    display: flex;
    justify-content: space-between;
}

.switch-on {
    --el-switch-on-color: #13ce66;
    --el-switch-off-color: #ff4949;
}

.switch-off {
    --el-switch-on-color: #ff4949;
    --el-switch-off-color: #13ce66;
}

.custom-font-style {
    font-size: 13px;
    font-weight: 550;
}

.approval-button {
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 0 5px rgba(0, 255, 0, 0);
}

.approval-button:hover {
    border-color: rgb(3, 70, 3);
}
.row-green {
    background-color: #c8e6c9 !important;
}
.row-red {
    background-color: #ffcdd2 !important;
}
.row-yellow {
    background-color: #fff9c4 !important;
}
</style>
