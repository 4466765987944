<template>
    <el-form :model="formData" :rules="rules" ref="form" class="card-tail w-full half-width" label-position="top" v-loading="$waiting.is('loading')">
        <div class="p-5">
            <header class="text-header-blue text-xl font-semibold mb-3">Skatteverket</header>
            <div class="flex space-x-2">
                <el-form-item class="w-full" label="Redovisningsperiod">
                    <el-select class="w-full" v-model="formData.vatPeriod" placeholder="--- Välj redovisningsperiod ---">
                        <el-option v-for="period in accountingPeriod" :label="period.name" :value="period.value" :key="period.value" />
                    </el-select>
                </el-form-item>
                <el-form-item class="w-full" label="Deklarationsombud">
                    <el-select class="w-full" v-model="formData.taxAgentIndividualId" placeholder="--- Välj ombud ---">
                        <el-option v-for="taxAgents in formData.taxAgentsList" :label="taxAgents.name" :value="taxAgents.value" :key="taxAgents.value" />
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex pb-5 space-x-2">
                <el-form-item class="w-full" label="Momsregistreringsnummer" prop="vatNumber">
                    <el-input v-model="formData.vatNumber" />
                </el-form-item>
                <el-form-item class="w-full" label="OCR-nummer skatteverket" prop="ocrSkvNumber">
                    <el-input v-model="formData.ocrSkvNumber" />
                </el-form-item>
            </div>
            <header class="text-header-blue text-xl font-semibold mb-3">Bokföring</header>
            <div class="flex space-x-2">
                <el-form-item class="w-full" label="Bokföringssytem">
                    <el-select class="w-full" v-model="formData.bookingSystem" placeholder="--- Välj bokföringssytem ---">
                        <el-option v-for="bookingSystem in bookingSystems" :label="bookingSystem.name" :value="bookingSystem.value" :key="bookingSystem.value" />
                    </el-select>
                </el-form-item>
                <el-form-item class="w-full" label="Startmånad för räkenskapsår">
                    <el-select class="w-full" v-model="formData.startMonth" placeholder="--- Välj startmånad ---">
                        <el-option v-for="month in months" :label="month.name" :value="month.value" :key="month.value" />
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex space-x-2">
                <el-form-item class="w-full" label="Typ på första året">
                    <el-select class="w-full" v-model="formData.financialYearStart" placeholder="--- Välj startmånad ---">
                        <el-option v-for="yearType in firstYearType" :label="yearType.name" :value="yearType.value" :key="yearType.value" />
                    </el-select>
                </el-form-item>
                <el-form-item class="w-full" label="Klientmall">
                    <el-select class="w-full" v-model="formData.clientTemplateId" placeholder="--- Välj klientmall ---">
                        <el-option v-for="template in clientTemplate" :label="template.name" :value="template.value" :key="template.value" />
                    </el-select>
                </el-form-item>
            </div>
            <div class="flex">
                <el-form-item label="Startdatum för bolaget" class="w-1/2" prop="startDate">
                    <DateInputField :pre-selected-value="formData.startDate" @change="formData.startDate = $event" />
                </el-form-item>
            </div>
            <div>
                <el-form-item>
                    <el-checkbox label="Moms & deklarationsombud" name="type" v-model="formData.declarationAgent" />
                </el-form-item>
                <el-form-item>
                    <el-checkbox label="Anställda rapporterad till SKV" name="type" v-model="formData.employeesReportedToSkv" />
                </el-form-item>
            </div>
        </div>
        <el-form-item class="px-5">
            <el-button type="primary" @click="onSubmit">Spara</el-button>
            <el-button @click="goBack()">Tillbaka</el-button>
        </el-form-item>
    </el-form>
</template>
<script>
import Api from "../clients.api";
import moment from "moment";

export default {
    data() {
        return {
            formData: {},
            bookingSystems: [
                { name: "Bolageriet", value: 3 },
                { name: "Bolageriet V1 & V2", value: 4 },
                { name: "Bolageriet V2", value: 5 },
            ],
            months: [
                { name: "Januari", value: 1 },
                { name: "Februari", value: 2 },
                { name: "Mars", value: 3 },
                { name: "April", value: 4 },
                { name: "Maj", value: 5 },
                { name: "Juni", value: 6 },
                { name: "Juli", value: 7 },
                { name: "Augusti", value: 8 },
                { name: "September", value: 9 },
                { name: "Oktober", value: 10 },
                { name: "November", value: 11 },
                { name: "December", value: 12 },
            ],
            firstYearType: [
                { name: "Normalt", value: 0 },
                { name: "Förlängt", value: 1 },
                { name: "Förkortat", value: 2 },
            ],
            clientTemplate: [
                { name: "Standard enskild firma", value: 1 },
                { name: "Handel/Kassaregister", value: 3 },
                { name: "Egen enskild firma", value: 4 },
                { name: "Huvudbolag", value: 5 },
                { name: "Australien", value: 6 },
                { name: "Kortare betalningsvillkor", value: 7 },
                { name: "Astand Music", value: 8 },
                { name: "Standard Aktiebolag", value: 9 },
                { name: "Flera försäljnigskonton AB", value: 10 },
            ],
            accountingPeriod: [
                { name: "Ej angivet", value: 0 },
                { name: "År", value: 1 },
                { name: "År (26 feb)", value: 2 },
                { name: "Kvartal", value: 3 },
                { name: "Månad 12:e", value: 4 },
                { name: "Ej momsregistrerad", value: 5 },
                { name: "Månad 26:e innan", value: 6 },
            ],
            rules: {
                vatNumber: [{ max: 20, message: "Momsregistreringsnummer får inte vara mer än 20 tecken", trigger: ["blur", "change"] }],
                ocrSkvNumber: [{ max: 80, message: "OCR-nummer skatteverket får inte vara mer än 80 tecken", trigger: ["blur", "change"] }],
                startDate: [{ pattern: /(\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/, message: "Felaktigt datum (åååå-mm-dd)", trigger: "blur" }],
            },
        };
    },

    components: {
        DateInputField: () => import(/* webpackChunkName: "DateInputField" */ "@/components/DateInputField.vue"),
    },

    async created() {
        this.getFinanceInfo();
    },

    methods: {
        async getFinanceInfo() {
            this.$waiting.start("loading");
            this.formData = await Api.getFinance(this.$route.params.clientId);
            if (this.formData.startDate) {
                this.formData.startDate = moment(this.formData.startDate).format("YYYY-MM-DD");
            }
            this.formData.taxAgentsList.push({ name: "--- Välj ombud ---", value: 0 });
            this.$waiting.end("loading");
        },

        async onSubmit() {
            await this.validateForm();
            this.$waiting.start("loading");
            await Api.updateClientFinance({ clientId: this.$route.params.clientId, clientDetails: this.formData });
            this.$waiting.end("loading");
            this.$notify.success({ title: "Utfört" });
        },

        async validateForm() {
            await new Promise((resolve, reject) => {
                this.$refs.form.validate(valid => {
                    if (valid) {
                        resolve();
                    } else {
                        reject(this.scrollUp());
                    }
                });
            });
        },

        scrollUp() {
            window.scrollTo({ top: window.innerHeight / 3, behavior: "smooth" });
        },
        goBack() {
            this.$router.push({ path: "/clientList" });
        },
    },
};
</script>
<style scoped>
@media screen and (min-width: 1383px) {
    .half-width {
        width: 50%;
    }
}
</style>
